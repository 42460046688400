var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"image-offer"},[_c('div',{staticClass:"image-box",style:({
    borderRadius: 'var(--imageBoxBorderRadius)'
  })},[_c('img',{class:{ 'image-height': !_vm.productImageScale },attrs:{"alt":"icon","src":_vm.iconUrl}}),(
        _vm.active &&
        _vm.discount != '' &&
        _vm.cardLayoutStyle == 'card-layout-1' &&
        _vm.discountPosition == 'bottom-left'
      )?_c('div',{staticClass:"offer",class:['offer-card-1-' + _vm.discountPosition]},[_c('div',{staticClass:"svg-holder"},[_c('svg',{attrs:{"width":"100%","height":"100%","top":"0","left":"0","viewBox":"0 0 56 20","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('rect',{attrs:{"width":"56","height":"20","rx":"4","fill":"#ffffff00"}}),_c('rect',{staticClass:"background_c",attrs:{"width":"46.1","height":"20","fill":"#2F80ED"}}),_c('path',{staticClass:"background_c",attrs:{"fill-rule":"evenodd","clip-rule":"evenodd","d":"M55.9524 0L46 9.5V0H55.9524ZM56 19.9545V20H46V9.5L56 19.9545Z","fill":"#2F80ED"}})])]),_c('div',{staticClass:"offer-text-container"},[_c('div',{staticClass:"offer-text"},[_vm._v(_vm._s(_vm.discount))])])]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }